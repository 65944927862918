(function($) {
  var $window                 = $(window);
  var $document               = $(document);
  var $html                   = $('html');
  var $body                   = $('body');
  var $cursor                 = $('.cursor');
  var $wipe                   = $('.wipe');

  var $windowWidth            = $window.width();
  var $windowHeight           = $window.height();
  var $isMedium               = ($windowWidth <= 900);
  var $isSmall                = ($windowWidth <= 640);
  var $scrollTop              = $window.scrollTop();

  var $scrollMagicController  = new ScrollMagic.Controller();
  var $scrollMagicScenes      = [];

  var $feature                = false;
  var $featureVideo           = false;
  var $featureVideoPlayed     = false;
  var $featureTimeout         = false;
  var $featurePaused          = false;
  var $emergecyVideo          = false;
  var $processSwiper          = false;
  var $carouselSwiper         = false;
  var $jobsSwiper             = false;
  var $initLoad               = true;

  // window resize
  function windowResize() {
    $windowWidth    = $window.width();
    $windowHeight   = $window.height();
    $isMedium       = ($windowWidth <= 900);
    $isSmall        = ($windowWidth <= 640);
  }

  // window scroll
  function windowScroll() {
    $scrollTop = $window.scrollTop();
  }

  // check if touch enabled
  function isTouchDevice() {
    return (('ontouchstart' in window) ||
     (navigator.maxTouchPoints > 0) ||
     (navigator.msMaxTouchPoints > 0));
  }

  // cursor movement
  function moveCursor(e) {
    var $x = e.clientX;
    var $y = e.clientY;

    $cursor.css({ transform:'translate3d('+$x+'px, '+$y+'px, 0)' });
  }

  // open menu
  function openMenu() {
    if($featureVideo && !$featureVideo.paused) {
      $featurePaused = true;
      pauseFeature();
    }
    $html.addClass('state--menu-open');
    $('.menu-toggle').attr('data-cursor', 'white');
  }

  // close menu
  function closeMenu() {
    if($featurePaused) {
      $featurePaused = false;
      playFeature();
    }
    $html.removeClass('state--menu-open');
    $('.menu-toggle').attr('data-cursor', '');
    $cursor.removeClass('hover white yellow');

    closeContactForm();
    resetForms();
  }

  // toggle menu
  function toggleMenu() {
    if($html.hasClass('state--menu-open')) {
      closeMenu();
    } else {
      openMenu();
    }
  }

  // reveal contact form
  function revealContactForm() {
    $html.addClass('state--contact-reveal');
  }

  // conceal contact form
  function concealContactForm() {
    $html.removeClass('state--contact-reveal');
  }

  // open contact form
  function openContactForm() {
    openMenu();

    $html.addClass('state--contact-open');
  }

  // close contact form
  function closeContactForm() {
    $html.removeClass('state--contact-open');
  }

  // toggle contact form
  function toggleContactForm(e) {
    e.preventDefault();

    if($html.hasClass('state--contact-open')) {
      closeContactForm();
    } else {
      openContactForm();
    }
  }

  function setupSelects() {
    $('select').each(function() {
      var $select = $(this);

      checkSelect($select);
      $select.change(function() {
        checkSelect($select);
      });
    });
  }

  // check select values
  function checkSelect($select) {
    if($select.val() === '') {
      $select.addClass('is-empty');
    } else {
      $select.removeClass('is-empty');
    }
  }

  // contact form success
  function openSuccessMessage(e) {

    var $formWrapper = $('#'+e.detail.unitTag);

    var $name = $formWrapper.find('input[name="your-name"]').val();
    if($name === '') {
      $name = 'Unknown';
    }

    if($formWrapper.parent().hasClass('drawer__contact')) {
      $('.drawer__contact .success .name').html($name);
      $('.drawer__contact .nav, .drawer__contact .wpcf7').hide();
      $('.drawer__contact .success').show();

      // Google Analytics event tracking
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualFormSubmission',
        'formTitle': 'Contact form submission'
      });
      /*gtag('event', 'generate_lead', {
        'event_category': 'engagement',
        'event_label': 'Contact form submission',
        'value': 1
      });*/
    } else {
      $('.job-form .success .name').html($name);
      $('.job-form h2, .job-form .wpcf7, .job-form .job-form_legal').hide();
      $('.job-form .success').show();
      
      // Google Analytics event tracking
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        'event': 'virtualFormSubmission',
        'formTitle': 'Job application form submission'
      });
      /*gtag('event', 'generate_lead', {
        'event_category': 'engagement',
        'event_label': 'Job application form submission',
        'value': 1
      });*/
    }

    resetForms();
  }

  function resetForms() {
    // reset fields
    $('input[name="your-name"]').val('');
    $('input[name="your-email"]').val('');
    $('input[name="your-link"]').val('');
    $('input[name="your-attachments"]').val('');
    $('textarea[name="your-message"]').val('');

    // reset and reinitialize drag & drop uploads
    //$('.wpcf7-form .codedropz-upload-handler').remove();
    //window.initDragDrop();
  }

  // reset contact
  function resetContact() {
    $('.drawer__contact .nav, .drawer__contact .wpcf7').show();
    $('.drawer__contact .success').hide();
  }

  // open emergency
  function openEmergency() {
    $('.emergency').show();

    if($emergencyVideo) {
      var $playPromise = $emergencyVideo.play();

      if($playPromise !== undefined) {
        $playPromise.then(function() {

        })
        .catch(function() {

        });
      }
    }
  }

  // close emergency
  function closeEmergency() {
    $('.emergency').hide();

    if($emergencyVideo) {
      $emergencyVideo.pause();
      $emergencyVideo.currentTime = 0;
    }
  }

  // set up feature
  function setupFeature() {
    $feature = false;
    $featureVideo = false;
    $featureVideoPlayed = false;

    if($('.feature').length) {
      $feature = $('.feature');

      if($feature.find('video').length) {
        $featureVideo = $feature.find('video').get(0);

        if($feature.hasClass('loop')) {
          $featureVideo.loop = true;
        }
        if(!$feature.hasClass('autoplay')) {
          pauseFeature();
        }

        $featureVideo.ontimeupdate = function() {
          var $percent = Math.floor((100 / $featureVideo.duration) * $featureVideo.currentTime);
          $('.progress').css({ width:$percent+'%' });
        };
      }
    }
  }

  // end feature
  function endFeature() {
    if($featureVideo) {
      $featureVideo.onended = function() {
        if(!$featureVideo.loop) {
          if(!$isMedium) {
            gsap.to(window, { scrollTo:($feature.height()), duration:1, ease:Expo.easeOut, onComplete:function() {
              $featureVideo.loop = true;
            } });
          } else {
            $featureVideo.loop = true;
          }
        }
      };
    }
  }

  // feature scroll
  function featureScroll() {
    if($feature) {
      if($featureTimeout) {
        clearTimeout($featureTimeout);
        $featureTimeout = false;
      }

      if($scrollTop >= $windowHeight) {
        $html.addClass('state--hide-video');
        pauseFeature();
      } else {
        $html.removeClass('state--hide-video');
        playFeature();
      }

      var $featureHeight = $feature.outerHeight()-1;
      if($scrollTop >= $featureHeight) {
        $html.addClass('state--feature-scrolled');
        enableScrollMagicScenes();
        ScrollTrigger.refresh();
        AOS.refresh();
      } else {
        $html.removeClass('state--feature-scrolled');
        disableScrollMagicScenes();
        AOS.refresh();
      }
    } else {
      $html.addClass('state--feature-scrolled');
      AOS.refresh();
    }
  }

  // mute feature
  function muteFeature() {
    if($featureVideo) {
      $featureVideo.muted = true;
      $('.feature .volume-toggle').addClass('muted');
    }
  }

  // unmute feature
  function unmuteFeature() {
    if($featureVideo) {
      $featureVideo.muted = false;
      $('.feature .volume-toggle').removeClass('muted');
    }
  }

  // toggle feature volume
  function toggleFeatureVolume() {
    if($('.feature .volume-toggle').hasClass('muted')) {
      unmuteFeature();
    } else {
      muteFeature();
    }
  }

  // pause feature
  function pauseFeature() {
    if($featureVideo) {
      $featureVideo.pause();
      $featureVideo.loop = true;
    }
  }

  // play feature
  function playFeature() {
    if($featureVideo) {
      var $playPromise = $featureVideo.play();

      if($playPromise !== undefined) {
        $playPromise.then(function() {
          if(!$featureVideoPlayed) {
            playFeature();
            pageLoaded();
          }
          $featureVideoPlayed = true;
        })
        .catch(pageLoaded);
      }
    }
  }

  // toggle feature state
  function toggleFeaturePlay() {
    if($('.feature .play-toggle').hasClass('paused')) {
      playFeature();
    } else {
      pauseFeature();
    }
  }

  // feature ready
  function featureReady() {
    if(!$featureVideoPlayed) {
      playFeature();
      pageLoaded();
    }
  }

  // toggle video state
  function toggleVideoPlay(e) {
    e.preventDefault();

    var $button = $(this);
    var $video = $button.parent().find('video')[0];

    if($button.hasClass('paused')) {
      $video.play();
      $button.removeClass('paused');

      $video.controls = true;
    } else {
      $video.pause();
      $button.addClass('paused');
    }
  }

  // page loaded
  function pageLoaded() {
    gsap.to($wipe, { x:'-100%', y:'0%', duration:0.5, ease:Expo.easeOut, onComplete:function() {
      $html.removeClass('state--transitioning');

      if($feature && !$featureVideo) {
        $featureTimeout = setTimeout(function() {
          if(!$isMedium) {
            gsap.to(window, { scrollTo:($feature.height()), duration:1, ease:Expo.easeOut });
          }
        }, 3000);
      }
    } });
  }

  // page loading
  function pageLoading() {
    if($featureVideo && $feature.hasClass('autoplay')) {
      $featureVideo.oncanplay = featureReady;
      if($featureVideo.readyState > 3) {
        featureReady();
      }
    } else {
      $document.imagesLoaded(pageLoaded);
    }
  }

  // back to top
  function backToTop(e) {
    e.preventDefault();

    gsap.fromTo($wipe, { x:'0%', y:'100%' }, { x:'0%', y:'0%', duration:0.5, ease:Expo.easeOut, onComplete:function() {
      gsap.set(window, { scrollTo:0 });
      gsap.fromTo($wipe, { x:'0%', y:'0%' }, { x:'0%', y:'-100%', duration:0.5, ease:Expo.easeOut });
    } });
  }

  // destroy scroll magic scenes
  function destroyScrollMagicScenes() {
    for(var $i=0; $i<$scrollMagicScenes.length; $i++) {
      var $scene = $scrollMagicScenes[$i];
      $scene.destroy(true);
    }
    $scrollMagicScenes = [];
  }

  // enable scroll magic scenes
  function enableScrollMagicScenes() {
    for(var $i=0; $i<$scrollMagicScenes.length; $i++) {
      var $scene = $scrollMagicScenes[$i];

      if(!$isMedium) {
        $scene.enabled(true);
      }
    }
  }

  // disable scroll magic scenes
  function disableScrollMagicScenes() {
    for(var $i=0; $i<$scrollMagicScenes.length; $i++) {
      var $scene = $scrollMagicScenes[$i];

      if(!$isMedium) {
        $scene.enabled(false);
      }
    }
  }

  function moduleAnimations() {
    destroyScrollMagicScenes();

    if(!$isSmall) {
      $('.module--images:not(.uniform)').each(function() {
        var $module = $(this);
        var $images = $module.find('.module__image');

        if($images.length > 1) {
          $images.each(function() {
            var $img            = $(this);
            var $imgHeight      = $img.height();
            var $gridItem       = $img.parent();
            var $gridItemHeight = $gridItem.height();

            if($imgHeight < $gridItemHeight) {
              var $tl           = new TimelineMax();
              var $fromY        = 0;
              var $toY          = $gridItemHeight - $imgHeight;
              var $triggerHook  = 0;
              var $duration     = $toY;

              $tl.fromTo($img, 1, { y:$fromY }, { y:$toY, ease:Linear.easeNone });

              var $scene = new ScrollMagic.Scene({
                triggerElement:$gridItem.get(0),
                triggerHook:$triggerHook,
                duration:$duration
              }).setTween($tl).addTo($scrollMagicController);

              $scrollMagicScenes.push($scene);
            }
          });
        }
      });
    }
  }

  // set up work animations
  function workAnimations() {
    destroyScrollMagicScenes();

    $('.posts li').each(function() {
      var $image = $(this).find('.post-item__image');
      var $animation = $(this).find('.work-animation');

      if($animation.length) {
        gsap.set($animation, { height:$image.outerHeight() });

        if($animation.hasClass('work-animation--goodman')) {
          animateGoodman($animation);
        } else if($animation.hasClass('work-animation--novus')) {
          animateNovus($animation);
        } else if($animation.hasClass('work-animation--canadian-rocks')) {
          animateCanadianRocks($animation);
        } else if($animation.hasClass('work-animation--video-scroll')) {
          animateVideoScroll($animation);
        } else if($animation.hasClass('work-animation--canadamark')) {
          animateCanadamark($animation);
        }
      }
    });
  }

  // custom animation: Video scroll
  function animateVideoScroll($animation) {
    var $tl             = new TimelineMax();
    var $video          = $animation.find('video').get(0);
    var $videoDuration  = 0;
    var $triggerHook    = 1;
    var $duration       = $windowHeight + $animation.find('.video-wrapper').outerHeight();

    if(isTouchDevice()) {
      $video.autoplay = true;
      $video.loop = true;
      $video.play();
    } else {
      $video.autoplay = false;
      $video.loop = false;
      $video.pause();

      if($video.readyState > 0) {
        setupVideoScroll();
      } else {
        $video.onloadeddata = setupVideoScroll;
      }
    }

    function setupVideoScroll() {
      $videoDuration = $video.duration;

      $tl.fromTo($video, 1, { currentTime: 0 }, { currentTime: $videoDuration, ease:Quad.easeOut });

      var $scene = new ScrollMagic.Scene({
        triggerElement:$video,
        triggerHook:$triggerHook,
        duration:$duration
      }).setTween($tl).addTo($scrollMagicController);

      $scrollMagicScenes.push($scene);
    }
  }

  // custom animation: Canadamark
  function animateCanadamark($animation) {

  }

  // custom animation: Goodman Report
  function animateGoodman($animation) {
    var $tl           = new TimelineMax();
    var $img          = $animation.find('img');
    var $fromX        = 0;
    var $fromY        = 0;
    var $toX          = $animation.width()*0.75;
    var $toY          = -$animation.height()*1;
    var $triggerHook  = 0.5;
    var $duration     = $windowHeight/2 + $animation.outerHeight()/2;

    if($isSmall) {
      $fromY          = $animation.height()*0.25;
      $triggerHook    = 1;
      $duration       = $windowHeight + $animation.outerHeight()*2;
    }

    $tl.fromTo($img, 1, { x:$fromX, y:$fromY, rotation:0 }, { x:$toX, y:$toY, rotation:90, ease:Linear.easeNone });

    var $scene = new ScrollMagic.Scene({
      triggerElement:$animation.get(0),
      triggerHook:$triggerHook,
      duration:$duration
    }).setTween($tl).addTo($scrollMagicController);

    $scrollMagicScenes.push($scene);
  }

  // custom animation: Novus
  function animateNovus($animation) {
    var $tl           = new TimelineMax();
    var $img          = $animation.find('img');
    var $fromX        = $animation.width();
    var $toX          = -$fromX;
    var $triggerHook  = 1;
    var $duration     = $windowHeight + $animation.outerHeight();

    $tl.fromTo($img, 1, { x:$fromX }, { x:$toX, ease:Linear.easeNone });

    var $scene = new ScrollMagic.Scene({
      triggerElement:$animation.get(0),
      triggerHook:$triggerHook,
      duration:$duration
    }).setTween($tl).addTo($scrollMagicController);

    $scrollMagicScenes.push($scene);
  }

  // custom animation: Canadian Rocks
  function animateCanadianRocks($animation) {
    var $tl             = new TimelineMax();
    var $img            = $animation.find('img');
    var $iphoneContent  = $animation.find('.iphone-content');
    var $fromY          = 0;
    var $toY            = -($img.height() - $iphoneContent.height());
    var $triggerHook    = 0.5;
    var $duration       = $windowHeight/2 + $animation.outerHeight()/2;

    $tl.fromTo($img, 1, { y:$fromY }, { y:$toY, ease:Linear.easeNone });

    var $scene = new ScrollMagic.Scene({
      triggerElement:$animation.get(0),
      triggerHook:$triggerHook,
      duration:$duration
    }).setTween($tl).addTo($scrollMagicController);

    $scrollMagicScenes.push($scene);
  }

  // adjust process module
  function adjustProcessModule() {
    var $maxStepHeight = 0;

    $('.process__step-copy-wrapper .process__step-copy').each(function() {
      var $step = $(this);

      var $stepHeight = $step.outerHeight();
      if($stepHeight > $maxStepHeight) {
        $maxStepHeight = $stepHeight;
      }
    });

    $('.process__step-copy-wrapper').css({ height:$maxStepHeight });
  }

  // carousel swiper transition
  function carouselSwiperTransition(swiper) {
    var $index = swiper.realIndex;
    var $count = $('.swiper-pagination-bullet').length;

    if($index >= $count) {
      $index = swiper.realIndex - $count;
    }

    $('.swiper-pagination .swiper-pagination-bullet-active').removeClass('swiper-pagination-bullet-active');
    $('.swiper-pagination .swiper-pagination-bullet').eq($index).addClass('swiper-pagination-bullet-active');
  }

  // setup carousel swiper
  function setupCarouselSwiper() {
    if($('.carousel-swiper').length) {
      $carouselSwiper = new Swiper('.carousel-swiper', {
        loop: true,
        loopedSlides: 2,
        slideToClickedSlide: true,
        allowTouchMove: true,
        followFinger: true,
        pagination: {
          el: '.carousel-swiper .swiper-pagination',
          type: 'bullets'
        },
        navigation: {
          nextEl: '.carousel-swiper .swiper-button-next',
          prevEl: '.carousel-swiper .swiper-button-prev',
        },
        on: {
          transitionEnd:function(swiper) {
            swiper.update();
          }
        }
      });

      $document.on('mouseover', '.carousel-swiper .swiper-button-next, .carousel-swiper .swiper-slide-next', function() {
        $('.carousel-swiper .swiper-slide-next, .carousel-swiper .swiper-slide-duplicate-next').addClass('swiper-slide-hover');
      });

      $document.on('mouseout', '.carousel-swiper .swiper-button-next, .carousel-swiper .swiper-slide-next', function() {
        $('.carousel-swiper .swiper-slide-hover').removeClass('swiper-slide-hover');
      });
    } else {
      $carouselSwiper = false;
    }
  }

  // process swiper transition
  function processSwiperTransition(swiper) {
    var $index = swiper.realIndex;
    var $count = $('.swiper-pagination-bullet').length;

    if($index >= $count) {
      $index = swiper.realIndex - $count;
    }

    $('.swiper-pagination .swiper-pagination-bullet-active').removeClass('swiper-pagination-bullet-active');
    $('.swiper-pagination .swiper-pagination-bullet').eq($index).addClass('swiper-pagination-bullet-active');

    var $activeStep = $('.process__step-copy.active');
    if($activeStep.length) {
      $activeStep.removeClass('active');
      gsap.fromTo($activeStep, { x:'0%' }, { x:'-100%', duration:0.3, onComplete:function() {
        gsap.set($activeStep, { x:'150%' });
      } });
    }

    var $nextStep = $('.process__step-copy-wrapper .process__step-copy[data-index="'+$index+'"]');
    if($nextStep.length) {
      $nextStep.addClass('active');
      gsap.fromTo($nextStep, { x:'100%' }, { x:'0%', duration:0.3 });
    }
  }

  // setup process swiper
  function setupProcessSwiper() {
    if($('.process-swiper').length) {
      $processSwiper = new Swiper('.process-swiper', {
        slidesPerView: 'auto',
        loop: true,
        loopedSlides: 10,
        slideToClickedSlide: true,
        allowTouchMove: true,
        followFinger: true,
        breakpoints: {
          901: {
            allowTouchMove: false
          }
        },
        on: {
          transitionStart:processSwiperTransition,
          transitionEnd:function(swiper) {
            if($windowWidth <= 1200  && $windowWidth > 900) {
              swiper.update();
            }
          }
        }
      });
    } else {
      $processSwiper = false;
    }
  }

  // process button mouseover
  function processButtonMouseover() {
    var $index = $(this).data('index');
    var $copy = $('.process__step-copy-wrapper .process__step-copy[data-index="'+$index+'"]');

    if(!$copy.hasClass('active')) {
      gsap.fromTo($copy, { x:'150%' }, { x:'100%', duration:0.3 });
    }
  }

  // process button mouseout
  function processButtonMouseout() {
    var $index = $(this).data('index');
    var $copy = $('.process__step-copy-wrapper .process__step-copy[data-index="'+$index+'"]');

    if(!$copy.hasClass('active')) {
      gsap.fromTo($copy, { x:'100%' }, { x:'150%', duration:0.3 });
    }
  }

  // process pagination
  function processPagination() {
    var $bullet = $(this);

    if($processSwiper && !$bullet.hasClass('swiper-pagination-bullet-active')) {
      var $index = $('.swiper-pagination-bullet').index($bullet);
      if($index < $processSwiper.realIndex) {
        $index = $index + $('.swiper-pagination-bullet').length;
      }
      $processSwiper.slideToLoop($index);
    }
  }

  // adjust job module
  function adjustJobModule() {
    var $maxGridHeight = 0;

    $('.jobs-grid, .job-content .grid').each(function() {
      var $grid = $(this);

      $grid.css({ height:'auto' });

      var $gridHeight = $grid.outerHeight();
      if($gridHeight > $maxGridHeight) {
        $maxGridHeight = $gridHeight;
      }
    });

    $('.jobs-grid, .job-content .grid').css({ height:$maxGridHeight });
  }

  // partner button mouseover
  function partnerButtonMouseover() {
    if(!$html.hasClass('state--partner-active')) {
      var $btn = $(this);

      $btn.closest('.partner').addClass('active');
      $html.addClass('state--partner-hover');
    }
  }

  // partner button mouseout
  function partnerButtonMouseout() {
    if(!$html.hasClass('state--partner-active')) {
      var $btn = $(this);

      $btn.closest('.partner').removeClass('active');
      $html.removeClass('state--partner-hover');
    }
  }

  // open partner
  function openPartner(btn) {
    var $btn = $(btn);
    var $btnTop = $btn.get(0).getBoundingClientRect().top;
    var $partner = $btn.closest('.partner');
    var $bio = $partner.find('.partner__bio');
    var $bioTop = $bio.get(0).getBoundingClientRect().top;
    var $bioPaddingTop = ($bioTop > 103) ? $bioTop : 103;
    var $bioScrollTop = ($bioTop < 103) ? (103 - $bioTop) : 0;
    var $bioPaddingBottom = 38 + $windowHeight - $btnTop;

    $btn.html('Close');
    $btn.css({ top:$btnTop });

    $partner.addClass('active');
    $html.addClass('state--partner-active');
    $bio.css({ paddingTop:$bioPaddingTop, paddingBottom:$bioPaddingBottom });
    gsap.set($bio, { scrollTo:$bioScrollTop });
  }

  // close partner
  function closePartner(btn) {
    var $btn = $(btn);
    var $partner = $btn.closest('.partner');
    var $bio = $partner.find('.partner__bio');

    $btn.html('View bio');
    $btn.css({ top:'auto' });
    $partner.removeClass('active');
    $bio.css({ paddingTop:0, paddingBottom:0 });
    $html.removeClass('state--partner-active');
  }

  // toggle partner
  function togglePartner() {
    if($html.hasClass('state--partner-active')) {
      closePartner(this);
    } else {
      openPartner(this);
    }
  }

  // open job
  function openJob(e) {
    e.preventDefault();

    var $job = $(this).data('job');
    $('.job-overlay .job-content.active').removeClass('active');
    $($job).addClass('active');

    $html.addClass('state--job-open');
  }

  // close job
  function closeJob(e) {
    e.preventDefault();

    $html.removeClass('state--job-open');
  }

  // setup jobs swiper
  function setupJobsSwiper() {
    if($('.jobs-swiper').length) {
      $jobsSwiper = new Swiper('.jobs-swiper', {
        loop:true,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets'
        }
      });
    } else {
      $jobsSwiper = false;
    }
  }

  // setup heading highlights
  var $highlights = false;
  function setupHighlights() {
    if($highlights) {
      $highlights.revert();
      $highlights.split();
    } else {
      $highlights = new SplitText('.post-title, .post-type', { type: 'lines', linesClass: 'highlight' });
    }
  }

  function initGlobal() {
    // window resize
    $window.resize(windowResize);

    // window scroll
    $window.scroll(windowScroll);

    // cursor movement
    $document.on('mousemove', moveCursor);

    // cursor hover states
    $document.on('mouseover', 'a, button, input, textarea, .swiper-button-prev, .swiper-button-next', function() {
      $cursor.addClass('hover');
    });
    $document.on('mouseout', 'a, button, input, textarea, .swiper-button-prev, .swiper-button-next', function() {
      $cursor.removeClass('hover');
    });
    $document.on('mouseover', '[data-cursor]', function() {
      $cursor.addClass($(this).attr('data-cursor'));
    });
    $document.on('mouseout', '[data-cursor]', function() {
      $cursor.removeClass($(this).attr('data-cursor'));
    });

    // menu
    $document.on('click', '.menu-toggle', toggleMenu);
    $document.on('click', '.mask', closeMenu);

    // contact
    $document.on('mouseover', '.drawer__menu a[href="#contact"]', revealContactForm);
    $document.on('mouseout', '.drawer__menu a[href="#contact"]', concealContactForm);
    $document.on('click', 'a[href="#contact"]', toggleContactForm);

    // set up emergency
    if($('.emergency video').length) {
      $emergencyVideo = $('.emergency video').get(0);
    }

    // emergency
    $('.emergency__open').click(openEmergency);
    $('.emergency__close').click(closeEmergency);

    // back to top
    $document.on('click', 'a[href="#top"]', backToTop);

    // feature scroll
    $window.scroll(featureScroll);

    // feature toggles
    $document.on('click', '.feature .volume-toggle', toggleFeatureVolume);
    $document.on('click', '.feature .play-toggle', toggleFeaturePlay);

    // video toggles
    //$document.on('click', '.module__image .volume-toggle', togglevideoVolume);
    $document.on('click', '.module__image .play-toggle', toggleVideoPlay);

    // process buttons
    $document.on('mouseover', '.swiper-slide .btn', processButtonMouseover);
    $document.on('mouseout', '.swiper-slide .btn', processButtonMouseout);
    //$document.on('click', '.process .swiper-pagination-bullet', processPagination);

    // partner buttons
    $document.on('mouseover', '.partner .btn', partnerButtonMouseover);
    $document.on('mouseout', '.partner .btn', partnerButtonMouseout);
    $document.on('click', '.partner .btn', togglePartner);

    // jobs
    //$document.on('click', '.job-item', openJob);
    //$document.on('click', '.job-overlay .back', closeJob);
  }

  function resetPage() {
    // destroy process swiper
    // destroy jobs swiper
  }

  function initPage() {
    // set up feature
    setupFeature();

    // page loading
    pageLoading();

    // end feature
    endFeature();

    // remove conflicting textarea attributes
    $('textarea[name="your-message"]').removeAttr('rows').removeAttr('cols');

    // setup event listeners for forms
    $('div.wpcf7 > form').each(function() {
      var $form = $(this)[0];

      if($($form).parent().parent().hasClass('newsletter-content')) {
        $form.addEventListener('wpcf7mailsent', function() {
          window.location = '/newsletter-thanks/';
        }, false);
      } else {
        $form.addEventListener('wpcf7mailsent', openSuccessMessage, false);
      }
      //$form.addEventListener('wpcf7submit', openSuccessMessage, false); // TESTING ONLY
    });

    // reinitialize contact form 7 after page transition/load
    if(!$initLoad) {
      $('div.wpcf7 > form').each(function() {
        var $form = $(this)[0];

        wpcf7.init($form);

        if(wpcf7.cached && typeof wpcf7.refill === "function") {
          wpcf7.refill($form);
        }
      });

      // reinitialize drag & drop uploads
      window.initDragDrop();
    }

    $initLoad = false;

    setupSelects();

    // split .post-title, .post-type headings
    $highlights = false;
    setupHighlights();
    $window.resize(setupHighlights);
  }

  function setupHomeIndicators() {
    $('.page-content, .module:not(.module--scrolling-image)').each(function() {
      var $section = $(this);
      var $indicator = $('<button class="home-indicator_button" />');
      $indicator.click(function() {
        gsap.to(window, { scrollTo:($section.offset().top) });
      });
      $('.home-indicators').append($indicator);

      ScrollTrigger.create({
        trigger: $section[0],
        start: 'top 50%',
        end: 'bottom 50%',
        onEnter: function() {
          $('.home-indicator_button.active').removeClass('active');
          $indicator.addClass('active');
        },
        onEnterBack: function() {
          $('.home-indicator_button.active').removeClass('active');
          $indicator.addClass('active');
        }
      });
    });

    ScrollTrigger.create({
      trigger: '.home-wrapper',
      pin: '.home-indicators',
      anticipatePin: true,
      start: 'top top',
      end: 'bottom bottom'
    });
  }

  function adjustScrollingContentModules() {
    $('.module--scrolling-content').each(function() {
      var $module = $(this);
      var $copyHeight = $module.find('.module__copy').outerHeight();
      $module[0].style.setProperty('--copy-height', $copyHeight+'px');
    });
  }

  function setupScrollingContentModules() {
    $('.module--scrolling-content').each(function() {
      var $module = $(this);
      var $moduleCopy = $module.find('.module__copy');
      var $copyHeight = $moduleCopy.outerHeight();
      var $scrollingBlockWrappers = $module.find('.scrolling-content_block-wrapper');

      $scrollingBlockWrappers.each(function(index) {
        var $blockWrapper = $(this);
        var $block = $blockWrapper.find('.scrolling-content_block');
        var $isFirst = (index === 0);
        var $isLast = (index === ($scrollingBlockWrappers.length-1));

        var $prevBlock = false;
        if(index > 0) {
          $prevBlock = $scrollingBlockWrappers.eq(index - 1).find('.scrolling-content_block');

          ScrollTrigger.create({
            trigger: $blockWrapper[0],
            start: function() {
              var $offset = 75 + $copyHeight + $prevBlock.outerHeight();
              if($isMedium) {
                $offset -= $copyHeight;
              }

              return 'top '+$offset+'px';
            },
            onEnter: function() {
              if($prevBlock) {
                gsap.to($prevBlock[0], {
                  opacity: 0,
                  duration: 0.4
                });
              }
            },
            onLeaveBack: function() {
              if($prevBlock) {
                gsap.to($prevBlock[0], {
                  opacity: 1,
                  duration: 0.4
                });
              }
            }
          });
        }
      });
    });
  }

  function setupThreeColumnContent() {
    $('.module--content-three-column').each(function() {
      var $module = $(this);
      var $scrollingBlocks = $module.find('.module__copy');

      $scrollingBlocks.each(function(index) {
        var $scrollingBlock = $(this);
        $scrollingBlock.attr('data-aos-delay', (100 * (index + 1)));
      });
    });
  }

  function setupScrollingImageModule() {
    var $tl = new TimelineMax({
      scrollTrigger: {
        trigger: '.module--scrolling-image',
        start: 'top 100%',
        end: 'bottom 0%',
        scrub: 1
      }
    });

    var $xFrom = '25vw'
    var $xTo = '-25vw';
    if($isSmall) {
      $xFrom = '100vw'
      $xTo = '-100vw';
    } else if($isMedium) {
      $xFrom = '50vw'
      $xTo = '-50vw';
    }

    $tl.fromTo('.scrolling-image', {
      x: $xFrom
    }, {
      x: $xTo,
      duration: 1
    });

    // change image once off screen
    if($('.scrolling-image').length > 1) {
      var $leftOnce = false;
      ScrollTrigger.create({
        trigger: '.module--scrolling-image',
        start: 'top 100%',
        end: 'bottom 0%',
        onLeave: function() {
          var $activeImage = $('.scrolling-image.active');
          var $nextImage = $activeImage.next();
          if($nextImage.length === 0) {
            $nextImage = $('.scrolling-image').first();
          }

          $activeImage.removeClass('active');
          $nextImage.addClass('active');

          $leftOnce = true;
        },
        onLeaveBack: function() {
          if($leftOnce) {
            var $activeImage = $('.scrolling-image.active');
            var $prevImage = $activeImage.prev();
            if($prevImage.length === 0) {
              $prevImage = $('.scrolling-image').last();
            }

            $activeImage.removeClass('active');
            $prevImage.addClass('active');
          }
        }
      });
    }
  }

  function initHome() {
    $('.modules').imagesLoaded(function() {
      adjustScrollingContentModules();
      setupScrollingContentModules();
      ScrollTrigger.refresh();
    });

    $window.resize(function() {
      adjustScrollingContentModules();

      setTimeout(function() {
        ScrollTrigger.refresh();
      }, 500);
    });

    if($isMedium) {
      $('.home-indicators').remove();
    } else {
      setupScrollingImageModule();
      setupThreeColumnContent();
      setupHomeIndicators();
    }

    AOS.init({
      once: true,
      duration: 1000
    });
  }

  function initWork() {
    // work animations
    $('.posts').imagesLoaded(workAnimations);
    $window.resize(workAnimations);
  }

  function initApproach() {
    // process module
    adjustProcessModule();
    $window.resize(adjustProcessModule);

    // process swiper
    setupProcessSwiper();
  }

  function initAbout() {
    // module animations
    $('.module--images').imagesLoaded(moduleAnimations);
    if($('.module--images').length) {
      setTimeout(moduleAnimations, 250);
    }
    $window.resize(moduleAnimations);

    // job module
    adjustJobModule();
    $window.resize(adjustJobModule);

    // jobs swiper
    setupJobsSwiper();
  }

  function initLanding() {
    // module animations
    $('.module--images').imagesLoaded(moduleAnimations);
    if($('.module--images').length) {
      setTimeout(moduleAnimations, 250);
    }
    $window.resize(moduleAnimations);

    // carousel swiper
    setupCarouselSwiper();
  }

  function initSingle() {
    // module animations
    $('.module--images').imagesLoaded(moduleAnimations);
    if($('.module--images').length) {
      setTimeout(moduleAnimations, 250);
    }
    $window.resize(moduleAnimations);
  }

  // initialize everything
  initGlobal();

  var $options = [
    {
      from: '(.*)',
      to: '(.*)',
      in: function(next) {
        $cursor.removeClass('hover white yellow');
        gsap.set(window, { scrollTo:0 });
        UTIL.loadEvents();
      },
      out: function(next) {
        $html.addClass('state--transitioning');
        gsap.fromTo($wipe, { x:'100%', y:'0%' }, { x:'0%', y:'0%', duration:0.5, ease:Expo.easeOut, onComplete:function() {
          closeMenu();
          next();
        } });
      }
    }
  ];
  var $swup = new Swup({
    plugins: [new SwupBodyClassPlugin(), new SwupJsPlugin($options)]
  });

  $swup.on('pageView', function() {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'virtualPageview',
      'pageUrl': window.location.pathname + window.location.search,
      'pageTitle': document.title
    });
  });

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    'common': {
      init: function() {
        initPage();
      }
    },
    'page_template_home': {
      init: function() {
        initHome();
      }
    },
    'page_template_work': {
      init: function() {
        initWork();
      }
    },
    'page_template_approach': {
      init: function() {
        initApproach();
      }
    },
    'page_template_about': {
      init: function() {
        initAbout();
      }
    },
    'page_template_landing': {
      init: function() {
        initLanding();
      }
    },
    'single': {
      init: function() {
        initSingle();
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
